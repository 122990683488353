import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import esLocale from 'element-ui/lib/locale/lang/es'

Vue.use(VueI18n)

export default ({ app, store }) => {
  // Set i18n instance on app
  // This way we can use it in middleware and pages asyncData/fetch
  app.i18n = new VueI18n({
    locale: store.state.locale,
    fallbackLocale: 'es',
    dateTimeFormats: {
      es: {
        long: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      },
      en: {
        long: {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      }
    },
    messages: {
      en: Object.assign(require('~/locales/en.json'), enLocale),
      es: Object.assign(require('~/locales/es.json'), esLocale)
    }
  })
  Vue.use(Element, {
    i18n: (key, value) => app.i18n.t(key, value)
  })
}
