import cloneDeep from 'lodash.clonedeep'
import { resourceState, resourceMutations, resourceActions } from '~/mixins/resource-store'
import { integrationsTypeOptions, integrationsStatus, integrationsFrequency } from '~/utils/user-friendly-translations'
import { isoStringToDate, isoStringToDateTime } from '~/utils/date-utils'

export const state = () => ({
  ...cloneDeep(resourceState),
  resource: 'integrations/'
})

export const mutations = {
  ...cloneDeep(resourceMutations),
  SET_LIST_RESULTS (state, data) {
    for (const item of data.results) {
      item.data__vat_id = item.data.vat_id
      item.type = (item.type) ? integrationsTypeOptions[item.type] : ''
      item.status = (item.status) ? integrationsStatus[item.status] : ''
      item.frequency = (item.frequency) ? integrationsFrequency[item.frequency] : ''
      item.created_on = isoStringToDateTime(item.created_on)
      item.activated_on = (item.activated_on) ? isoStringToDate(item.activated_on) : ''
      item.day_of_month = (item.day_of_month) ? item.day_of_month : null
      item.day_of_week = (item.day_of_week) ? item.day_of_week : null
    }
    state.list.data.items = data.results
  }
}

export const actions = {
  ...cloneDeep(resourceActions)
}
