<template>
  <div>
    v{{ appVersion }}
  </div>
</template>

<script>
export default {
  data () {
    return {
      appVersion: process.env.APP_VERSION
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~assets/scss/variables';
  div {
    right: 0px;
    bottom: 0px;
    padding: 10px;
    position: fixed;
    color: $color-text-placeholder;
    font-size: $font-size-extra-small;
  }
</style>
