<template>
  <el-container v-loading.fullscreen.lock="fullscreenLoading">
    <el-main>
      <nuxt />
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import LayoutMixin from '@/mixins/layout'

export default {
  mixins: [LayoutMixin]
}
</script>
