import Footer from '@/components/Footer'

export default {
  components: { Footer },
  computed: {
    fullscreenLoading () {
      return this.$store.state.ui.loading
    }
  },
  mounted () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'NOTIFY_ERROR') {
        this.$notify.error({
          title: this.$t('layout.error'),
          dangerouslyUseHTMLString: true,
          message: mutation.payload.replace('\n', '<br>')
        })
      }
    })
  },
  destroyed () {
    this.unsubscribe()
  }
}
