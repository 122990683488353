import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export default function (options) {
  if (process.env.SENTRY_URL) {
    try {
      Sentry.init({
        dsn: process.env.SENTRY_URL,
        environment: process.env.NODE_ENV,
        release: `${process.env.PACKAGE_NAME}@${process.env.APP_VERSION}`,
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        ignoreErrors: ['ResizeObserver loop limit exceeded']
      })
    } catch (e) {
      // eslint-disable-next-line
      console.error('Error while setting up Sentry', e)
    }
  }
}
