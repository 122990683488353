export default function ({ redirect, $auth, route, store, app }) {
  const ignoredRoutes = ['login', 'sso-token']
  const accessPermissions = process.env.ACCESS_PERMISSIONS
  if (!ignoredRoutes.includes(route.name) && accessPermissions.length > 0) {
    for (const permission of accessPermissions) {
      if (!$auth.user.user_permissions.includes(permission)) {
        app.i18n.mergeLocaleMessage('en', { 'notEnoughPermissions': 'You don\'t have enough permissions' })
        app.i18n.mergeLocaleMessage('es', { 'notEnoughPermissions': 'No tienes suficientes permisos' })
        const errorMessage = app.i18n.t('notEnoughPermissions')
        store.commit('NOTIFY_ERROR', errorMessage)
        redirect('/login')
      }
    }
  }
}
