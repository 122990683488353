export default (options) => {
  const redirect = options.redirect
  const interval = 1000 * process.env.REFRESH_TOKEN_INTERVAL
  setInterval(async () => {
    const $axios = options.$axios
    const $auth = options.$auth || { loggedIn: false }
    const url = $auth.strategy.options.endpoints.refresh.url
    const tokenType = $auth.strategy.options.tokenType
    const rawToken = $auth.getToken($auth.strategy.name)
    if (rawToken) {
      try {
        const token = rawToken.replace(tokenType, '').trim()
        const response = await $axios.post(url, { token })
        if (response.statusText === 'OK') {
          const newToken = `${tokenType} ${response.data.token}`
          await $auth.setToken('local', newToken)
        } else {
          redirect('/logout')
        }
      } catch (error) {
        redirect('/logout')
      }
    }
  }, interval)
}
