export const invoicesStatuses = {
  PEN: 'Invoices.pending',
  SNT: 'Invoices.sent',
  ERR: 'Invoices.error'
}

export const integrationsTypeOptions = {
  CON: 'Integrations.typeOptions.Conecta',
  IES: 'Integrations.typeOptions.IESA',
  TAAF: 'Integrations.typeOptions.TAAF',
  REPORT: 'Integrations.typeOptions.Report',
  OMOF: 'Integrations.typeOptions.oneMailOneFile',
  OMOFWR: 'Integrations.typeOptions.oneMailOneFilesWithReport',
  OMSF: 'Integrations.typeOptions.oneMailSeveralFile',
  OMSFWR: 'Integrations.typeOptions.oneMailSeveralFilesWithReport',
  OOSI: 'Integrations.typeOptions.oneMailOneFileSeveralInvoices',
  OOSIWR: 'Integrations.typeOptions.oneMailOneFileSeveralInvoicesWithReport',
  OOWS: 'Integrations.typeOptions.oneMailOneFileWithSepa',
  OOWSWR: 'Integrations.typeOptions.oneMailOneFileWithSepaWithReport'
}

export const integrationsStatus = {
  ACT: 'Integrations.statusOptions.active',
  INA: 'Integrations.statusOptions.inactive'
}

export const integrationsFrequency = {
  DLY: 'Integrations.frequencyOptions.daily',
  WLY: 'Integrations.frequencyOptions.weekly',
  MLY: 'Integrations.frequencyOptions.monthly',
  FRD: 'Integrations.frequencyOptions.forced'
}
