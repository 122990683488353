/**
 * Specific error parser for django-rest-framework errors structure
 *
 * Error structure examples:
 *
 *     // Simple string
 *     "Error one"
 *
 *    // Array of Strings
 *    ["Error one", "Error two"]
 *
 *     // Detail
 *     { detail: "Authentication is required" }
 *
 *     // Simple form validation error
 *     { title: ["This field is required."], "description": ["This field is required."]}
 *
 *     // Simple list validation error
 *     [{}, {"title": ["This field is required."]}]
 *
 *     // Complex nested object validation error
 *     {"experience": [
 *         {}, {}, {"position": ["This field is required."], "employer": ["This field is required."]}
 *     ]}
 */
export default function (errors) {
  const KEYS_TO_AVOID = ['error', 'detail', 'non_field_errors']

  function capitalize (str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  let messages = ''
  const errorsType = errors || false ? typeof errors.valueOf() : ''
  if (['string', 'object'].includes(errorsType) && !Array.isArray(errors)) {
    errors = [errors]
  }
  for (let error of errors) {
    const errorType = error || false ? typeof error.valueOf() : ''
    if (errorType === 'string' || Array.isArray(error)) {
      error = { error }
    }
    Object.keys(error).forEach((key) => {
      messages += messages.length === 0 ? '' : '\n '
      let values = error[key]
      if (!Array.isArray(values)) {
        values = [values]
      }
      const description = values.join(' ')
      if (KEYS_TO_AVOID.includes(key)) {
        messages += description
      } else {
        messages += `${capitalize(key)}: ${description}`
      }
    })
  }
  return messages
}
