import qs from 'qs'
import parseErrors from '@/utils/error-parser'

export default function ({ app, store, $axios, redirect }) {
  const ongoingRequests = {}

  // auth handling && canceling duplicated calls
  $axios.allowedUrlsForMultipleRequests = []
  $axios.onRequest((config) => {
    const $auth = app.$auth
    const url = config.url
    const allowMultipleRequest = $axios.allowedUrlsForMultipleRequests.includes(url)
    const thereIsAnOnGoingRequestForUrl = ongoingRequests[url] || false
    if (thereIsAnOnGoingRequestForUrl && !allowMultipleRequest) {
      ongoingRequests[url].cancel()
    }
    const source = $axios.CancelToken.source()
    ongoingRequests[url] = source
    config.cancelToken = source.token
    if ($auth.loggedIn) {
      config.headers.common.Authorization = $auth.getToken($auth.strategy.name)
    }
    config.paramsSerializer = (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })

  // error handling
  $axios.onError((error) => {
    const response = error.response || {}
    const code = parseInt(response.status || 0)
    const data = response.data
    if (code === 401 || code === 403) {
      const message = parseErrors(data)
      store.commit('NOTIFY_ERROR', message)
      redirect('/logout')
    } else if (code === 404) {
      redirect('/404')
    } else if (code >= 500) {
      redirect('/server-error')
    } else if (!$axios.isCancel(error)) { // any other error, except cancelled requests
      const message = parseErrors(data)
      store.commit('NOTIFY_ERROR', message)
    }
  })
}
