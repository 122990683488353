<template>
  <el-container v-loading.fullscreen.lock="fullscreenLoading">
    <el-header>
      <NavBar :menu="menu" />
    </el-header>
    <el-main>
      <nuxt />
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import NavBar from '@/components/NavBar'
import LayoutMixin from '@/mixins/layout'

export default {
  components: { NavBar },
  mixins: [LayoutMixin],
  data () {
    return {
      menu: [
        {
          'url': '/',
          'label': this.$t('Menu.integrator'),
          'submenu': []
        },
        {
          'url': '/invoices',
          'label': this.$t('Menu.invoices'),
          'submenu': []
        },
        {
          'url': '/deliveries',
          'label': this.$t('Menu.deliveries'),
          'submenu': []
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/variables";
.el-header {
  padding: 0;
}
</style>

<i18n>
{
  "en": {
    "Menu": {
      "integrator": "Integrator",
      "invoices": "Invoices",
      "deliveries": "Deliveries"
    }
  },
  "es": {
    "Menu": {
      "integrator": "Integraciones",
      "invoices": "Facturas",
      "deliveries": "Envíos"
    }
  }
}
</i18n>
