import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d9a66b2 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _b7755eea = () => interopDefault(import('../pages/deliveries/index.vue' /* webpackChunkName: "pages/deliveries/index" */))
const _1c79cb42 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _040e483a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0e33a900 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _05b4b1f4 = () => interopDefault(import('../pages/server-error.vue' /* webpackChunkName: "pages/server-error" */))
const _61da5e27 = () => interopDefault(import('../pages/integrations/add.vue' /* webpackChunkName: "pages/integrations/add" */))
const _10de5e44 = () => interopDefault(import('../pages/integrations/update/_id.vue' /* webpackChunkName: "pages/integrations/update/_id" */))
const _0173f9b3 = () => interopDefault(import('../pages/deliveries/_id.vue' /* webpackChunkName: "pages/deliveries/_id" */))
const _29c22887 = () => interopDefault(import('../pages/invoices/_id.vue' /* webpackChunkName: "pages/invoices/_id" */))
const _12174520 = () => interopDefault(import('../pages/sso/_token.vue' /* webpackChunkName: "pages/sso/_token" */))
const _345138cc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0d9a66b2,
    name: "404"
  }, {
    path: "/deliveries",
    component: _b7755eea,
    name: "deliveries"
  }, {
    path: "/invoices",
    component: _1c79cb42,
    name: "invoices"
  }, {
    path: "/login",
    component: _040e483a,
    name: "login"
  }, {
    path: "/logout",
    component: _0e33a900,
    name: "logout"
  }, {
    path: "/server-error",
    component: _05b4b1f4,
    name: "server-error"
  }, {
    path: "/integrations/add",
    component: _61da5e27,
    name: "integrations-add"
  }, {
    path: "/integrations/update/:id?",
    component: _10de5e44,
    name: "integrations-update-id"
  }, {
    path: "/deliveries/:id",
    component: _0173f9b3,
    name: "deliveries-id"
  }, {
    path: "/invoices/:id",
    component: _29c22887,
    name: "invoices-id"
  }, {
    path: "/sso/:token?",
    component: _12174520,
    name: "sso-token"
  }, {
    path: "/",
    component: _345138cc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
