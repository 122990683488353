import cloneDeep from 'lodash.clonedeep'
import { resourceState, resourceMutations, resourceActions } from '~/mixins/resource-store'
import { isoStringToDate } from '~/utils/date-utils'
import { invoicesStatuses } from '~/utils/user-friendly-translations'

export const state = () => ({
  ...cloneDeep(resourceState),
  resource: 'invoices/',
  response: null
})

export const mutations = {
  ...cloneDeep(resourceMutations),
  SET_LIST_RESULTS (state, data) {
    for (const item of data.results) {
      item.data__invoiced_date = item.data && item.data.invoiced_date ? isoStringToDate(item.data.invoiced_date) : ''
      item.status = (item.status) ? invoicesStatuses[item.status] : ''
    }
    state.list.data.items = data.results
  },
  SET_DETAIL (state, data) {
    data.data__invoiced_date = data.data && data.data.invoiced_date ? isoStringToDate(data.data.invoiced_date) : ''
    state.detail = data
  },
  SET_RESPONSE (state, data) {
    state.response = data
  }
}

export const actions = {
  ...cloneDeep(resourceActions),
  async changeInvoiceStatus (context, params) {
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const url = context.state.resource + 'set-status/'
      const response = await this.$axios.post(url, params)
      const data = response.data || {}
      context.commit('SET_RESPONSE', data.message || '')
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  }
}
