<template>
  <el-menu :router="true" mode="horizontal" default-active="/">
    <el-menu-item index="/" class="app-name">
      {{ appName }}
    </el-menu-item>
    <el-menu-item :index="item.url" v-for="item in menu" :key="item.url" :is="item.submenu.length===0?'el-menu-item':'el-submenu'">
      {{ item.submenu.length===0?item.label:'' }}
      <template slot="title">
        <span v-if="item.submenu.length>0">{{ item.label }}</span>
      </template>
      <el-menu-item :index="sub.url" v-for="sub in item.submenu" :key="sub.url">
        {{ sub.label }}
      </el-menu-item>
    </el-menu-item>
    <el-menu-item index="/logout" class="logout">
      <el-tooltip :content="$t('NavBar.logout')">
        <i class="el-icon-switch-button" />
      </el-tooltip>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    menu: { type: Array, required: true }
  },
  data () {
    return {
      appName: process.env.APP_NAME
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~assets/scss/variables';
  .app-name {
    display: inline-block;
    font-family: $font-family-serif;
    font-size: $font-size-extra-large;
    text-transform: capitalize;
  }
  .logout {
    i { margin-top: -4px; }
    float: right !important;
  }
</style>

<i18n>
{
  "en": {
    "NavBar": {
      "logout": "Logout"
    }
  },
  "es": {
    "NavBar": {
      "logout": "Salir"
    }
  }
}
</i18n>
