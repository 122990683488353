export const state = () => ({
  locales: ['en', 'es'],
  locale: 'es',
  ui: {
    loading: false,
    loadingCount: 0
  }
})

export const mutations = {
  SET_LANG (state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale
    }
  },
  SHOW_LOADING (state) {
    state.ui.loadingCount++
    if (state.ui.loadingCount > 0) {
      state.ui.loading = true
    }
  },
  HIDE_LOADING (state) {
    if (state.ui.loadingCount > 0) {
      state.ui.loadingCount--
    }
    if (state.ui.loadingCount === 0) {
      state.ui.loading = false
    }
  },
  NOTIFY_ERROR (context, error) {
    // eslint-disable-next-line
    console.error('ERROR:', error)
  }
}

export const actions = {
  showLoading (context) {
    context.commit('SHOW_LOADING')
  },
  hideLoading (context) {
    context.commit('HIDE_LOADING')
  }
}
