/**
 * Date and datetime utils
 */
export function isoStringToDateTime (isoString) {
  const date = new Date(isoString)
  const dateTimeString = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
  return dateTimeString
}

export function isoStringToDate (isoString) {
  const date = new Date(isoString)
  const dateString = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
  return dateString
}
