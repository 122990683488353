import cloneDeep from 'lodash.clonedeep'
import { resourceState, resourceMutations, resourceActions } from '~/mixins/resource-store'
import { isoStringToDateTime } from '~/utils/date-utils'

export const state = () => ({
  ...cloneDeep(resourceState),
  resource: 'deliveries/'
})

export const mutations = {
  ...cloneDeep(resourceMutations),
  SET_LIST_RESULTS (state, data) {
    for (const item of data.results) {
      item.started_on = (item.started_on) ? isoStringToDateTime(item.started_on) : ''
    }
    state.list.data.items = data.results
  },
  SET_DETAIL (state, data) {
    data.started_on = (data.finished_on) ? isoStringToDateTime(data.started_on) : ''
    data.finished_on = (data.finished_on) ? isoStringToDateTime(data.finished_on) : ''
    for (const item of data.delivered_invoices) {
      item.created_on = (item.created_on) ? isoStringToDateTime(item.created_on) : ''
    }
    state.detail = data
  }
}

export const actions = {
  ...cloneDeep(resourceActions)
}
