export const resourceState = {
  resource: null,
  list: {
    data: {
      items: [],
      meta: {
        total: 0,
        pageSize: null
      }
    },
    params: { page: 1 },
    selectedItems: {
      all: false,
      selected: [],
      excluded: []
    }
  },
  detail: {}
}

export const resourceMutations = {
  SET_LIST_META (state, data) {
    state.list.data.meta.total = data.count
    if (state.list.data.meta.pageSize === null || state.list.data.meta.pageSize < data.results.length) {
      state.list.data.meta.pageSize = data.results.length
    }
  },
  SET_LIST_RESULTS (state, data) {
    state.list.data.items = data.results
  },
  SET_LIST_PARAMS (state, data) {
    state.list.params = Object.assign({}, data)
  },
  SET_SELECTED_ITEMS (state, data) {
    state.list.selectedItems = {
      all: data.all,
      selected: data.selected.slice(),
      excluded: data.excluded.slice()
    }
  },
  RESET_DETAIL (state, data) {
    state.detail = {}
  },
  SET_DETAIL (state, data) {
    state.detail = data
  },
  SET_RESPONSE (state, data) {
    state.detail = data
  }
}

export const resourceActions = {
  updateListParams (context, params) {
    context.commit('SET_LIST_PARAMS', params)
  },

  updateSelectedItems (context, params) {
    context.commit('SET_SELECTED_ITEMS', params)
  },

  async list (context) {
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const params = context.state.list.params
      const response = await this.$axios.get(context.state.resource, { params })
      const data = response.data || {}
      context.commit('SET_LIST_RESULTS', data)
      context.commit('SET_LIST_META', data)
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  },

  async retrieve (context, params) {
    context.commit('RESET_DETAIL')
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const url = `${context.state.resource}${params.id}/`
      if (params.id !== undefined) {
        delete params.id
      }
      const response = await this.$axios.get(url, { params })
      const data = response.data || {}
      context.commit('SET_DETAIL', data)
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  },

  async create (context, params) {
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const url = context.state.resource
      const response = await this.$axios.post(url, params)
      const data = response.data || {}
      context.commit('SET_RESPONSE', data)
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  },

  async update (context, params) {
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const url = `${context.state.resource}${params.id}/`
      if (params.id !== undefined) {
        delete params.id
      }
      const response = await this.$axios.put(url, params)
      const data = response.data || {}
      context.commit('SET_RESPONSE', data)
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  },

  async patch (context, params) {
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const url = `${context.state.resource}${params.id}/`
      const response = await this.$axios.patch(url, params)
      const data = response.data || {}
      context.commit('SET_RESPONSE', data)
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  },

  async destroy (context, params) {
    context.commit('SHOW_LOADING', null, { root: true })
    try {
      const url = `${context.state.resource}${params.id}/`
      if (params.id !== undefined) {
        delete params.id
      }
      const response = await this.$axios.delete(url)
      const data = response.data || {}
      context.commit('SET_RESPONSE', data)
    } finally {
      context.commit('HIDE_LOADING', null, { root: true })
    }
  }
}
